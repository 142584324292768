export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART"

export const ADD_DISCOUNT_CODE = "ADD_DISCOUNT_CODE"

export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_TO_CART"

export const CREATE_ORDER_OBJECT = "CREATE_ORDER_OBJECT"

export const CLEAR_CART = "CLEAR_CART"

export const CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS"

export const TOGGLE_SIDEBAR_HIDDEN = "TOGGLE_SIDEBAR_HIDDEN"

export const UPDATE_CART_TOTAL = "UPDATE_CART_TOTAL"

export const UPDATE_CURRENT_STORE_DETAILS = "UPDATE_CURRENT_STORE_DETAILS"

export const UPDATE_DELIVERY_ADDRESS = "UPDATE_DELIVERY_ADDRESS"

export const UPDATE_GUEST_USER_DETAILS = "UPDATE_GUEST_USER_DETAILS"

export const UPDATE_PICKUP_DETAILS = "UPDATE_PICKUP_DETAILS"

export const UPDATE_PAYMENT_DETAILS = "UPDATE_PAYMENT_DETAILS"

export const UPDATE_STORE_DELIVERY_FEE = "UPDATE_STORE_DELIVERY_FEE"

export const UPDATE_USER_AUTH = "UPDATE_USER_AUTH"

export const SET_TIME_WINDOW = "SET_TIME_WINDOW"

export const SET_DELIVERY_WINDOW = "SET_DELIVERY_WINDOW"

export const SET_CUSTOM_CHECKOUT = "SET_CUSTOM_CHECKOUT"

export const UPDATE_PICKUP_WINDOW = "UPDATE_PICKUP_WINDOW"

export const SET_IS_DELIVERY_DISCOUNT = "SET_IS_DELIVERY_DISCOUNT"

export const UPDATE_USER_DATA = "UPDATE_USER_DATA"

export const SET_SPECIAL_NOTES = "SET_SPECIAL_NOTES"

export const UPDATE_MISSING_ITEMS_PREF = "UPDATE_MISSING_ITEMS_PREF"
