import GlobalContextProvider from "../context/GlobalContextProvider"
import Layout from "../components/layout"
import "../styles/style.css"
import "../styles/listing.css"
import "../styles/layout.css"
import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"
import { init } from "../lib/sentry"
import { withStyles } from "@material-ui/core/styles"
import ProgressBar from "@badrap/bar-of-progress"
import { colors } from "styles/colors"
import Router from "next/router"
import { Toaster } from "react-hot-toast"
import { UserContext } from "../state/UserContext"
import { useUserData } from "custom-hooks/useUserData"
import SimpleReactLightbox from "simple-react-lightbox"
import { Analytics } from "@vercel/analytics/react"
import Script from "next/script"

const progress = new ProgressBar({
  size: 3,
  color: colors.businessPrimary,
  className: "bar-of-progress",
  delay: 100,
})

Router.events.on("routeChangeStart", progress.start)
Router.events.on("routeChangeComplete", progress.finish)
Router.events.on("routeChangeError", progress.finish)

init()

const styles = {
  root: {
    fontSize: 22,
    fontFamily: "PhantomSans",
  },
  containerRoot: {
    fontSize: 22,
    fontFamily: "PhantomSans",
  },
}

// This default export is required in a new `pages/_app.js` file.
const App = ({ Component, pageProps, classes }) => {
  const userData = useUserData()
  return (
    <>
      <UserContext.Provider value={userData}>
        <GlobalContextProvider>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
              style: {
                fontFamily: "PhantomSans",
              },
            }}
          />
          <Layout>
            <SimpleReactLightbox>
              <Component {...pageProps} />
              <Analytics />
            </SimpleReactLightbox>
          </Layout>
        </GlobalContextProvider>
      </UserContext.Provider>
      <script
        src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
        integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
        crossOrigin="anonymous"
      ></script>
      {/* Google tag (gtag.js) */}
      <Script
        strategy="afterInteractive"
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-582470478"
      ></Script>
      <Script id="google-ads-script" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || []; function gtag()
        {dataLayer.push(arguments);}
        gtag('js', new Date()); gtag('config', 'AW-582470478');`}
      </Script>

      <Script
        id="helpkit-widget"
        data-project-id="unqueuesupport"
        type="text/javascript"
        strategy="afterInteractive"
        src="https://www.helpkit.so/widget/script.js"
        async
      ></Script>
    </>
  )
}

export default withStyles(styles)(App)
