import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import shajs from "sha.js"

const DISCOUNT_CODES_COLLECTION = "discount-codes"
export const USERS_COLLECTION = "users"

let firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}
// firebase.functions().useFunctionsEmulator("http://localhost:5001");

export default firebase

export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const functions = firebase.functions()
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider()

export const submitOrder = firebase.functions().httpsCallable("createOrder")

export const completeOrder = firebase
  .functions()
  .httpsCallable("completeDraftOrder")

export const calculateDeliveryFee = firebase
  .functions()
  .httpsCallable("calculateDeliveryFee")

export const getLandingPageSnapshot = firebase
  .functions()
  .httpsCallable("getLandingPageSnapshot")

export const submitBusinessContactForm = firebase
  .functions()
  .httpsCallable("submitBusinessContactForm")

export const signInWithEmail = (email, password) =>
  auth.signInWithEmailAndPassword(email, password)

export const registerWithEmail = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password)

export const getPromoCode = (promoCode, companyId) =>
  firestore
    .collection(DISCOUNT_CODES_COLLECTION)
    .where("code", "==", promoCode)
    .where("companyId", "==", companyId)
    .get()

export const updatePromoCodeUses = docId =>
  firestore
    .collection(DISCOUNT_CODES_COLLECTION)
    .doc(docId)
    .update({ uses: firebase.firestore.FieldValue.increment(1) })

export const notifyWhenBack = async (companyId, type, value) => {
  const docRef = firestore.collection("notify-when-back").doc(companyId)
  await docRef
    .set(
      {
        [type]: firebase.firestore.FieldValue.arrayUnion(value),
      },
      { merge: true }
    )
    .catch(err => console.log(err))
}
export const getBlockedDeliveryDays = () =>
  firestore.collection("content-snapshots").doc("blocked-delivery-dates").get()

export const getFreeDeliveryDays = () =>
  firestore.collection("free-delivery-promos").where("active", "==", true).get()

export const addWaitListEntry = async data => {
  // Create a Hash out of their email and the product ID so that we do not have duplicate entries
  const idHash = shajs("sha256")
    .update(data.email + data.productId)
    .digest("hex")
  const docRef = firestore.collection("wait-list-entries").doc(idHash)

  await docRef
    .set(
      {
        ...data,
        createdAt: new Date(),
      },
      { merge: true }
    )
    .catch(err => {
      throw new Error(err)
    })
}
