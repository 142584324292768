import React, { useState } from "react"
import styled from "styled-components"
import Socials from "./socials"
import AppStoreLinks from "components/appStoreLinks/appStoreLinks"
import Link from "next/link"
import Spacer from "components/spacer"
import FACPaymentLogos from "./layout/fac-payment-logos"
import { colors } from "styles/colors"

export default function CustomFooter({
  accentColor,
  address,
  backgroundColor,
  dark,
  description,
  email,
  facebookUrl,
  instagramUrl,
  phone,
  storeName,
  textColor,
  twitterUrl,
}) {
  return (
    <CustomFooterWrapper backgroundColor={backgroundColor}>
      <CustomInnerContainer>
        <MiniGrid textColor={textColor} count={2}>
          <Heading>{storeName}</Heading>
          {description ? <Text>{description}</Text> : []}
        </MiniGrid>

        <MiniGrid count={2}>
          <Address color={textColor}>
            <Text>{address}</Text>
          </Address>
          <Address color={textColor}>
            <ExternalLinkItem
              style={{ fontSize: 18, color: textColor }}
              href={`mailto:${email}`}
            >
              {email}
            </ExternalLinkItem>
            <Spacer size={10} />
            <ExternalLinkItem
              style={{ fontSize: 18, color: textColor }}
              href={`tel:${phone}`}
            >
              {phone}
            </ExternalLinkItem>
          </Address>
        </MiniGrid>
        <MiniGrid color={textColor} count={2}>
          <Socials
            color={dark ? "white" : "black"}
            instagram={instagramUrl}
            facebook={facebookUrl}
            twitter={twitterUrl}
          />
        </MiniGrid>
        <MiniGrid margin={false} count={2}>
          <Text color={textColor}>
            Website powered by{" "}
            <ExternalLinkItem
              style={{ color: textColor }}
              href="https://unqueue.app"
            >
              Unqueue
            </ExternalLinkItem>
          </Text>
          <AppStoreLinks />
          <Spacer size={20} />
          {/* <FACPaymentLogos /> */}
        </MiniGrid>
      </CustomInnerContainer>
    </CustomFooterWrapper>
  )
}

const CustomFooterWrapper = styled.footer`
  background: ${props => props.backgroundColor || colors.base};
  color: ${props => (props.dark ? "white" : "white")};
  width: 100%;
`
const CustomInnerContainer = styled.div`
  padding: 50px 20px;
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  @media (min-width: 800px) {
    grid-template-columns: repeat(4, auto);
  }
`
const MiniGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(${props => props.count}, min-content);
  gap: 15px;
  color: ${props => props.textColor || "white"};
  @media (min-width: 800px) {
    margin-top: ${props => props.margin && "31px"};
  }
`
const ExternalLinkItem = styled.a`
  color: white;
  text-decoration: underline;
  font-family: "PhantomSans";

  :hover {
    color: white;
  }
`
const SubscribeBtn = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 20px;
  margin-bottom: 5px;
`

const Heading = styled.h3`
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 10px;
`
const Text = styled.p`
  color: ${props => props.color};
  font-size: 18px;
  line-height: 1.4;
  display: block;
  font-family: "PhantomSans";
  font-weight: normal;
  max-width: 250px;
  margin: 0;

  @media (max-width: 800px) {
    max-width: none;
  }
`
const Address = styled.div`
  color: ${props => props.color};
`
