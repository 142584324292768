import React from "react"
import styled from "styled-components"
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"
import { motion } from "framer-motion"
import { colors } from "../styles/colors"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 120px;
  margin-bottom: 15px;
`
const SocialLink = styled(motion.a)`
  color: white;
  transition: 0.2s ease;
  :hover {
    color: ${colors.businessPrimary};
  }
`

const Socials = ({
  color,
  facebook = "https://www.facebook.com/unqueueapp",
  instagram = "https://www.instagram.com/unqueue_app/?hl=en",
  twitter = "https://twitter.com/unqueue_app",
}) => (
  <Container>
    {facebook && (
      <SocialLink
        whileHover={{ scale: 1.1 }}
        href={facebook}
        target="_blank"
        rel="noreferrer"
      >
        <FaFacebookF size={25} color={color} />
      </SocialLink>
    )}
    {instagram && (
      <SocialLink
        whileHover={{ scale: 1.1 }}
        href={instagram}
        target="_blank"
        rel="noreferrer"
      >
        <FaInstagram size={25} color={color} />
      </SocialLink>
    )}
    {twitter && (
      <SocialLink
        whileHover={{ scale: 1.1 }}
        href={twitter}
        target="_blank"
        rel="noreferrer"
      >
        <FaTwitter size={25} color={color} />
      </SocialLink>
    )}
  </Container>
)

export default Socials
