import { useEffect, useState } from "react"
import { auth, firestore, USERS_COLLECTION } from "../lib/firebase"
import { useAuthState } from "react-firebase-hooks/auth"

// Custom hook to read  auth record and user profile doc
export function useUserData() {
  const [user] = useAuthState(auth)
  const [savingUserData, setSavingUserData] = useState(false)
  const [userData, setUserData] = useState(null)

  const updateUserData = async data => {
    try {
      setSavingUserData(true)
      await firestore.collection(USERS_COLLECTION).doc(user.uid).update(data)
      const userDoc = await firestore.doc(`users/${user.uid}`).get()
      setUserData({
        id: user.uid,
        ...userDoc.data(),
        // ...(userDoc.data().dob && {
        //     dob: userDoc.data().dob.toDate(),
        // }),
      })
      return true
    } catch (err) {
      console.log(err.message)
      return false
    } finally {
      setSavingUserData(false)
    }
  }

  useEffect(() => {
    // turn off realtime subscription
    let unsubscribe

    if (user) {
      const ref = firestore.collection("users").doc(user.uid)
      unsubscribe = ref.onSnapshot(doc => {
        setUserData({ id: doc.id, ...doc.data() })
      })
    } else {
      setUserData(null)
    }

    return unsubscribe
  }, [user])

  return { user, userData, savingUserData, setUserData, updateUserData }
}
