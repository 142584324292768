import React from "react"
import Footer from "./footer/footer"
import { useGlobalState } from "context/GlobalContextProvider"
import CustomFooter from "./custom-footer"
import { isSSR } from "lib/utils"

const Layout = ({ children }) => {
  const { isCustomCheckout, currentStoreDetails } = useGlobalState()
  return (
    <>
      {/* <Header /> */}
      <div
        style={{
          margin: `0 auto`,
          maxWidth: "100vw",
        }}
      >
        <main>{children}</main>
      </div>
      {!isSSR && (
        <>
          {isCustomCheckout ? (
            <CustomFooter
              storeName={currentStoreDetails?.name}
              description={currentStoreDetails?.description ?? null}
              address={currentStoreDetails?.address?.address}
              email={currentStoreDetails?.email}
              phone={currentStoreDetails?.phone}
              dark={currentStoreDetails?.dark ?? true}
              backgroundColor={currentStoreDetails?.footerBackgroundColor}
              textColor={currentStoreDetails?.footerTextColor}
              twitterUrl={currentStoreDetails.twitterUrl}
              facebookUrl={currentStoreDetails.facebookUrl}
              instagramUrl={currentStoreDetails.instagramUrl}
              accentColor="white"
            />
          ) : (
            <Footer siteTitle={`Title`} />
          )}
        </>
      )}
    </>
  )
}

export default Layout
