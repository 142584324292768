import moment from "moment"
import * as Sentry from "@sentry/react"
import { KOS_COMPANY_ID } from "./constants"
Sentry.init({
  dsn: "https://d2dd14ec78174be3980d9211b5ee4b57@o417959.ingest.sentry.io/5667296",
})

export const getPublicUrl = url => {
  //Trim the URL of any Access Keys, the Folder is public
  try {
    const urlParts = url.split("?GoogleAccessId")
    return urlParts[0]
  } catch (err) {
    return url
  }
}

export const isEmail = value => {
  return value.indexOf("@") !== -1
}

export const toCurrency = number => {
  return `$${parseFloat(number).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`
}

export const sluggedName = name => {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;"
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------"
  const p = new RegExp(a.split("").join("|"), "g")

  const sluggedNameFormat = name
    ?.toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text

  return sluggedNameFormat
}

export const getTodaysHours = openingDays => {
  const today = new Date()
  const day = moment(today).format("dddd").toString()

  //Get the store hours for said date
  const hoursIndex = openingDays?.findIndex(el => el.day === day)

  const openingTimeObj = openingDays ? openingDays[hoursIndex]?.openingTime : ""
  const closingTimeObj = openingDays ? openingDays[hoursIndex]?.closingTime : ""
  const openToday = openingDays ? openingDays[hoursIndex]?.open : false

  return { openingTimeObj, closingTimeObj, openToday }
}

export const getStoreOpeningAndClosing = (openingTimeObj, closingTimeObj) => {
  const openingTime = moment(
    (openingTimeObj?._seconds ?? openingTimeObj?.seconds) * 1000
  ).format("h:mmA")
  const closingTime = moment(
    (closingTimeObj?._seconds ?? closingTimeObj?.seconds) * 1000
  ).format("h:mmA")

  const now = moment()

  const date = now.format("YYYY-MM-DD")
  const storeOpenTime = moment(date + " " + openingTime, "YYYY-MM-DD h:mmA")
  const storeCloseTime = moment(date + " " + closingTime, "YYYY-MM-DD h:mmA")

  return { storeOpenTime, storeCloseTime }
}

export const _captureError = e => {
  Sentry.captureException(e)
}
export const getStoreOpeningAndClosingFirestore = (
  openingTimeObj,
  closingTimeObj
) => {
  const openingTime = moment(openingTimeObj.toDate()).format("h:mmA")
  const closingTime = moment(closingTimeObj.toDate()).format("h:mmA")

  const now = moment()

  const date = now.format("YYYY-MM-DD")
  const storeOpenTime = moment(date + " " + openingTime, "YYYY-MM-DD h:mmA")
  const storeCloseTime = moment(date + " " + closingTime, "YYYY-MM-DD h:mmA")

  return { storeOpenTime, storeCloseTime }
}

export const getUniqueId = (length = 20) => {
  let str = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefgijklmnopqrstuvwxyz1234567890"
  let arr = str.split("")
  let id = ""
  for (let i = 0; i < length; i++)
    id += arr[Math.floor(Math.random() * arr.length)]
  return id
}
export const sanitise = x => {
  if (isNaN(x)) {
    return 0
  }
  return x
}

export const formatPhoneForFirebase = phone => {
  return phone.replace(" ", "").replace("-", "").replace("-", "")
}

export const _filterForOnlineCompanies = (companies = []) => {
  return companies.filter(
    el =>
      el.masterVerified &&
      el.emailVerified &&
      el.hasCategories &&
      (el.storeOn || el.storeOnFlagged)
  )
}

// Return website url if website is being run in browser and not SSR
// Else return empty string ''
export const getWebsiteRootUrl = () =>
  typeof window !== "undefined" ? window.location.origin : ""

// Sets specified localstorage key to value specified, and creates the key if it doesn't exist
// Return: True if value was set, false if it wasn't (from localstorage error or code running in SSR mode)
// Params:
// key: type string
// value: type string
export const setStringToLocalStorage = (key, value) => {
  if (typeof window !== "undefined") {
    try {
      window.localStorage.setItem(key, JSON.stringify(value))

      return true
    } catch {
      return false
    }
  }
  return false
}

// Returns the value of localStorage entry, or null if it doesn't exist.
// params: key: type string
export const getFromLocalStorage = key =>
  // return false if not running in browser, or 'cart' values from localStorage or falsy from JSON.parse
  typeof window !== "undefined"
    ? JSON.parse(window.localStorage.getItem(key))
    : null

// params: key: type string. The item to remove
// If key exists it's removed
export const removeFromLocalStorage = keyName => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(keyName)
  }
}

export function formatAmountForFAC(amount, currency = "TTD") {
  let numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  })
  const parts = numberFormat.formatToParts(amount)
  let zeroDecimalCurrency = true
  for (let part of parts) {
    if (part.type === "decimal") {
      zeroDecimalCurrency = false
    }
  }
  return zeroDecimalCurrency ? amount : Math.round(amount * 100)
}

// Probably need to extract this to a hook
// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "TTD",
})

export const numberToCurrency = price => {
  return formatter.format(price)
  // return formatter.format(price).replace(/(\.|,)00$/g, "")
}

export const isSSR = typeof window === "undefined"

export function roundToNearestTime(date, duration, method) {
  return moment(Math[method](+date / +duration) * +duration)
}

export const displayConciergeFee = subtotal => {
  //Get rid of cents
  return numberToCurrency(Math.ceil(subtotal * 0.05))
}
