import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  img {
    margin: 0 10px;
    object-fit: contain;
    height: 30px;
  }
`

const FACPaymentLogos = () => (
  <Container>
    <img src="/first-atlantic-commerce-logo.png" />
    <img src="/MasterCard-SecureCode.png" />
    <img src="/verified-by-visa.png" />
  </Container>
)

export default FACPaymentLogos
