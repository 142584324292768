import React from "react"
import styled from "styled-components"
import {
    BUSINESS_APP_STORE_URL,
		BUSINESS_PLAY_STORE_URL,
		SHOPPER_APP_STORE_URL,
		SHOPPER_PLAY_STORE_URL,
} from "../../lib/constants"

const imageLinks = {
    ios:
        "https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg",
    android:
        "https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png",
}

export default function AppStoreLinks({ shopper = true }) {
    return (
        <Container>
            <a href={shopper ? SHOPPER_APP_STORE_URL : BUSINESS_APP_STORE_URL}>
                <AppStore src="/appstore.svg" />
            </a>
            <a href={shopper ? SHOPPER_PLAY_STORE_URL : BUSINESS_PLAY_STORE_URL}>
                <GooglePlay
                    src={"/en_badge_web_generic.png"}
                />
            </a>
        </Container>
    )

    return (
        <Container>
            <LinkContainer>
                <Badge app="ios">&nbsp;</Badge>
            </LinkContainer>
            <LinkContainer app="android">
                <Badge android>&nbsp;</Badge>
            </LinkContainer>
        </Container>
    )
}

const Container = styled.div`
    /* display: grid; */
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    /* grid-template-columns: 50% 50%; */
    z-index: 1;
    gap: 10px;
`
const LinkContainer = styled.div`
    width: 150px;
    height: 45px;
    display: inline-block;
`
const Badge = styled.a`
    background: ${props =>
        props.app && `url(${imageLinks[props.app]}) no-repeat`};
    background-size: contain;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    height: 100%;
    width: 100%;
    padding : 5px;
    /* width: 120px; */
    /* margin: 0 10px; */
    /* cursor: ${props => (props.home ? "default" : "pointer")}; */
    
    `
const AppStore = styled.img`
    width: 150px;
    cursor: ${props => (props.home ? "default" : "pointer")};
`
const GooglePlay = styled.img`
    cursor: ${props => (props.home ? "default" : "pointer")};
    width: 150px;
    flex: 1;
`