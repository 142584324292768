export const colors = {
  base: "#22252D",
  primary: "#10FFC6",
  shopperPrimary: "#10FFC6",
  businessPrimary: "#008db4",
  driverPrimary: "#FF4E00",
  unavailable: "#F04B4B",
  lightGrey: "#707070",
  sale: "#FF0000",
  success: "#00C569",
  preOrder: "#FFBF10",
  kosPrimary: "#ED1D24",
  kosBG: "#DA3931",
}
